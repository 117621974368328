<template>
  <v-row class="ma-0 pa-0 fill-height">
    <v-col cols="10" lg="5" xl="3"  class="d-flex align-center mx-auto fill-height">
      <v-row class="ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">
          <v-card width="100%" class="pa-8">
            <v-card-title class="d-flex flex-column font-weight-bold">
              <router-link to="/">
                <v-img src="@/assets/logo.svg" alt="MegaBusiness Logo" max-width="206.36"></v-img>
              </router-link>
            </v-card-title>
            <validation-observer ref="login" v-slot="{ passes }">
              <v-form @submit.prevent="passes(submit)">
                <v-card-text>
                  <validation-provider name="username" rules="required|email" v-slot="{ errors }">
                    <v-text-field type="email"
                                  autocomplete="email"
                                  :error-messages="errors"
                                  v-model="form.username"
                                  hint="Enter e-mail that you registered with"
                                  persistent-placeholder
                                  placeholder="Your e-mail or mobile number">
                      <template v-slot:label>
                        <label class="font-weight-bold">Login</label>
                      </template>
                    </v-text-field>
                  </validation-provider>
                  <validation-provider name="password" rules="required" v-slot="{ errors }">
                    <v-text-field type="password"
                                  autocomplete="current-password"
                                  :error-messages="errors"
                                  v-model="form.password"
                                  hint="Password could be restored"
                                  persistent-placeholder
                                  placeholder="Password">
                      <template v-slot:label>
                        <label class="font-weight-bold">Password</label>
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-card-text>
                <v-card-text >
                  <small>Are you not a member?</small>
                  <router-link style="text-decoration: none" to="request-membership"><small>&nbsp;Request Membership&nbsp;</small></router-link>
                </v-card-text>
                <v-card-actions class="d-flex flex-column justify-center">
                  <v-btn width="33%" height="49" rounded type="submit" color="primary">Log in</v-btn>
                  <div class="px-0 py-3">
                    <small>Forgot your password?&nbsp;</small>
                    <router-link
                           to="reset-password" style="text-decoration: none" color="primary">
                      <small>Reset</small>
                    </router-link>
                  </div>
                </v-card-actions>
              </v-form>
            </validation-observer>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Login",
  data () {
    return {
      form: {
        username: null,
        password: null
      }
    }
  },
  methods: {
    submit() {
      this.$http.post(`${process.env.VUE_APP_API_URL}/login`, this.form)
    }
  }
}
</script>

<style scoped lang="scss">
canvas {
  width: 100%;
  height: 100%;
}
</style>
